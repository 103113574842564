<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                <b-row class="mt-3">
                    <!-- Name -->
                    <b-col cols="12" sm="6" md="6">
                        <validation-provider #default="validationContext" name="name" rules="required">
                            <b-form-group label="Name" label-for="name">
                                <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12" sm="6" md="6">
                        <validation-provider name="maxParticipants">
                            <b-form-group label="Max participants" label-for="maxParticipants">
                                <b-form-input id="maxParticipants" v-model="form.max_participants" trim />
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col cols="12" sm="6" md="6">
                        <validation-provider name="contestList">
                            <b-form-group label="Choose a contest" label-for="contestList">
                                <v-select v-model="contest_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="contestsOption" :clearable="false" label="name" @input="fetchStageList(contest_id)" />
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col cols="12" sm="6" md="6">
                        <validation-provider name="stageList">
                            <b-form-group label="Choose a stage" label-for="stageList">
                                <v-select v-model="form.stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="stagesOption" :clearable="false" label="name" />
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col cols="12" sm="12" md="12">
                        <validation-provider name="Description">
                            <b-form-group label="Description" label-for="Description">
                                <b-form-input id="Description" v-model="form.description" trim />
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="12" md="12">
                        <b-form-group label="Notification text for added participants " label-for="notif_text">
                            <b-form-input id="notif_text" v-model="form.added_participants_notif_text" trim />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" sm="6" md="6">
                        <b-form-group label="Start date" label-for="datepicker-placeholder">
                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose Date" local="en" initial-date="2022-06-16" selected-variant="warning" class="mx-1" v-model="form.start_date" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6">
                        <b-form-group label="End date" label-for="datepicker-placeholder">
                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose Date" local="en" initial-date="2022-06-16" selected-variant="warning" class="mx-1" v-model="form.end_date" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="justify-content-center">
                    <b-form-group label="Is active?" label-for="is_active">
                        <b-form-checkbox v-model="form.is_active" class="mx-2 custom-control-success" name="check-button" switch />
                    </b-form-group>
                    <b-form-group label="Is published?" label-for="is_published">
                        <b-form-checkbox v-model="form.is_published" class="mx-2 custom-control-success" name="check-button" switch />
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex mt-2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                {{ groupId ? "update" : "add" }}
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import { BFormCheckbox, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormDatepicker } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useContestList from "../useContestList";
import ContestRepository from "@/abstraction/repository/contestRepository";
const contestRepository = new ContestRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BFormCheckbox,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BFormDatepicker,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {};

        const contestId = ref(null);
        const groupId = ref(null);
        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        if (router.currentRoute.params.groupId) {
            groupId.value = router.currentRoute.params.groupId;
        }
        const contest_id = ref();
        const contestsOption = ref([]);
        const stagesOption = ref();
        contestRepository.index().then((res) => {
            contestsOption.value = res.data;
        });
        const { storeGroupContest, updateContest, showContestGroup,updateContestGroup } = useContestList();
        // Register module

        const onSubmit = async () => {
            if (groupId.value) {
                updateContestGroup(router.currentRoute.params.groupId, form.value).then((res) => {
                    router.push({ name: "apps-contest-group-list" });
                });
            } else {
                storeGroupContest(form.value).then((res) => {
                    router.push({ name: "apps-contest-group-list" });
                });
            }
        };
        console.log(router.currentRoute.params.groupId);
        const fetchStageList = () => {
            contestRepository.fetchStageContest(router.currentRoute.params.contestId).then((res) => {
                stagesOption.value = res.data;
            });
        };
        fetchStageList();
        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showContestGroup(router.currentRoute.params.groupId);
            console.log(response);

            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
            if (response.is_active == 1) {
                form.value.is_active = true;
            }
            if (response.is_published == 1) {
                form.value.is_published = true;
            }
        };

        if (router.currentRoute.params.groupId) {
            showData();
        }

        return {
            form,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            contestId,
            contest_id,
            groupId,
            contestsOption,
            fetchStageList,
            stagesOption,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
